import { css, Global } from '@emotion/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'emotion-theming';
import { SnackbarProvider } from 'notistack';
import React from 'react';
// @ts-ignore
import getPageContext from './getPageContext';
import { css as customCss } from './styled';
import { COMBINED_THEME } from './themes';

const styles = css`
  * {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
  }

  body {
    margin: 0;
    background-color: #191c20;
    font-family: 'Lato', sans-serif;
    color: #e3e9f0;
    font-size: 1rem;

    @media (max-width: 800px) {
      overflow-x: hidden;
    }
  }

  a {
    text-decoration: none;
    transition: 0.7s;
    color: inherit;
  }
`;

function withRoot(Component: any) {
  class WithRoot extends React.Component {
    muiPageContext: any | undefined;

    constructor(props: any) {
      super(props);
      this.muiPageContext = getPageContext();
    }

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      return (
        <StylesProvider
          generateClassName={this.muiPageContext.generateClassName}
        >
          {/* MuiThemeProvider makes the theme available down the React
              tree thanks to React context. */}
          <>
            <MuiThemeProvider theme={this.muiPageContext.theme}>
              {(() => {
                return (
                  // @ts-ignore
                  <>
                    <CssBaseline />
                    <ThemeProvider theme={COMBINED_THEME}>
                      <SnackbarProvider
                        maxSnack={1}
                        classes={{
                          variantSuccess: customCss(() => ({
                            backgroundColor: '#7cb305 !important'
                          })),
                          variantError: customCss(() => ({
                            backgroundColor: '#ff4d4f !important'
                          })),
                          variantInfo: customCss(() => ({
                            backgroundColor: '#40a9ff !important'
                          })),
                          variantWarning: customCss(() => ({
                            color: '#613400 !important',
                            backgroundColor: '#ffc53d !important'
                          }))
                        }}
                      >
                        <Component {...this.props} />
                      </SnackbarProvider>
                    </ThemeProvider>
                  </>
                );
              })()}
            </MuiThemeProvider>
            <Global styles={styles} />
          </>
        </StylesProvider>
      );
    }
  }

  return WithRoot;
}

export default withRoot;
